import {Configure, connectStateResults, Index} from 'react-instantsearch-dom';
import {Transition} from '@headlessui/react';
import {CompanyHits} from '@components/app-search/hits';
import React from 'react';
import {StateResultsProvided} from 'react-instantsearch-core';
import {useInstantSearch} from 'react-instantsearch-hooks-web';
import {MaterialSymbol} from '@components/material-symbol';
import Link from 'next/link';

type Props = StateResultsProvided & {
    visible: boolean;
    showOnEmpty?: boolean;
    onSelect?: () => void;
}
const SearchResults = (props: Props) => {
    const {searchState, visible,searchResults} = props
    return <Transition show={visible && (props.showOnEmpty || !!searchState?.query)}
                       enter="transition-opacity duration-200"
                       enterFrom="opacity-0"
                       enterTo="opacity-100"
                       leave="transition-opacity duration-400"
                       leaveFrom="opacity-100"
                       as="div"
                       className="absolute bg-white rounded-md text-slate-800 left-0 w-full overflow-hidden z-30 shadow-lg mt-2"
                       leaveTo="opacity-0">
        <Index indexName="production_businesses">
            {/*<h2 className="px-5 pb-2 pt-2 font-bold bg-primary-50 text-sm uppercase text-primary-500">Fyritøkur</h2>*/}
            <Configure hitsPerPage={15}/>
            <CompanyHits onSelect={props.onSelect}/>
            <Link href={{pathname: '/leita', search: '?q=' + encodeURIComponent(searchState.query ?? '')}}><a
                className="px-5 py-4 flex font-regular leading-none font-light items-center text-primary-500">Vís øll
                úrslit ({searchResults?.nbHits ?? '-'}) <MaterialSymbol className="text-base" icon="chevron_right"/></a></Link>
        </Index>
        {/*<Index indexName="development_categories">*/}
        {/*    <h2 className="bg-slate-200 px-4 py-1 text-slate-600">Vinnugreinar</h2>*/}
        {/*    <Configure hitsPerPage={2}/>*/}
        {/*    <CategoryHits/>*/}
        {/*</Index>*/}
    </Transition>
}
export const AppSearchResults = connectStateResults(SearchResults)
