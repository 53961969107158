import {signOut, useSession} from 'next-auth/react';
import initials from 'initials';
import splitbee from '@splitbee/web';
import {Menu, Transition} from '@headlessui/react';
import {Fragment, useEffect} from 'react';
import Link from 'next/link';
import {StarIcon, UserIcon} from '@heroicons/react/outline';
import {LogoutIcon} from '@heroicons/react/solid';
import classNames from 'classnames';

export const UserMenu = (props: { className?: string }) => {
    const logOut = async () => {
        await signOut();
    }
    const session = useSession()
    useEffect(() => {
        if (session?.data?.error != null && typeof session?.data?.error === 'string') {
            signOut()
        }
    }, [session])
    const userInitials = session.data?.user?.name ? initials(session.data?.user?.name) : '??'
    splitbee.user.set({
        status: session.status
    })
    return (
        <Menu as="div" className={classNames('relative inline-block text-left', props.className)}>
            {({open}) => (
                <>
                    <div>
                        {/*<Menu.Button*/}
                        {/*    className="hover:bg-opacity-10 transition-colors rounded-md hover:bg-slate-500 inline-flex justify-center w-full px-4 py-2 hover:border-primary-300 group text-sm font-medium text-primary-500 rounded-md hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-slate-500 focus-visible:ring-opacity-75">*/}
                        {/*    {userInitials}*/}
                        {/*    <ChevronDownIcon*/}
                        {/*        className="w-5 h-5 ml-2 -mr-1 text-primary-500 group-hover:text-primary-300"*/}
                        {/*        aria-hidden="true"*/}
                        {/*    />*/}
                        {/*</Menu.Button>*/}
                        <Menu.Button as="div"
                                     className="outline-none select-none overflow-hidden group cursor-pointer hover:bg-primary-100  relative transition-colors w-12 h-12 border-2 border-primary-500 rounded-full font-bold text-primary-500 grid place-items-center">
                            {userInitials}
                            {/*<ChevronDownIcon*/}
                            {/*    className={classNames('w-5 h-5 text-white transition-transform transition-opacity ease-out absolute bottom-0 left-[14px] pointer-events-none translate-y-4 group-hover:translate-y-0 group-hover:opacity-100')}*/}
                            {/*    aria-hidden="true"*/}
                            {/*/>*/}
                        </Menu.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items
                            className="absolute bg-primary-100 right-0 z-20 w-56 mt-2 origin-top-right text-primary-500 divide-y divide-gray-100 rounded-md shadow-sme focus:outline-none">
                            <div className="px-1 py-1">
                                <Menu.Item>
                                    {({active}) => (
                                        <Link passHref={true} href={'/account'}>
                                            <a
                                                className={`text-primary-500 group flex rounded-md transition-colors items-center w-full justify-between px-3 py-3 font-bold hover:bg-primary-300 hover:text-white text-sm`}>
                                                Vangamynd
                                                <UserIcon
                                                    className="w-5 h-5 mr-2"
                                                    aria-hidden="true"
                                                />
                                            </a>
                                        </Link>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({active}) => (
                                        <Link passHref={true} href={'/account/reviews'}>
                                            <button
                                                className={`text-primary-500 transition-colors group flex rounded-md items-center w-full justify-between px-3 py-3 font-bold text-sm hover:bg-primary-300 hover:text-white`}
                                            >
                                                Mínar Reytingar
                                                <StarIcon
                                                    className="w-5 h-5 mr-2"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </Link>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({active}) => (
                                        <button
                                            onClick={logOut}
                                            className={`text-primary-500 transition-colors group flex rounded-md items-center w-full justify-between px-3 py-3 font-bold text-sm hover:bg-primary-300 hover:text-white`}
                                        >
                                            Rita Út
                                            <LogoutIcon
                                                className="w-5 h-5 mr-2"
                                                aria-hidden="true"
                                            />
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </>)}
        </Menu>
    )
}
