import classNames from 'classnames';

type IconName = string;

interface Props {
    icon: IconName;
    size?: number;
    className?: string;
}

export const MaterialSymbol = (props: Props) => {
    return <span className={classNames('material-symbols-rounded', props.className)}>{props.icon}</span>
}
