import {Logo} from '@components/logo';
import {Fragment, PropsWithChildren} from 'react';
import Link from 'next/link';
import classNames from 'classnames';
import {SimplifiedNavTree} from 'lib/server/api';

const FooterItem = (props: PropsWithChildren<{ href: string, target?: string }>) => (
    <li>
        <Link href={props.href}>
            <a className={classNames('relative  focus:ring-1',
                'after:opacity-0 after:content-[\'\'] after:w-6/12 after:absolute after:h-1 after:left-0 after:-bottom-1 after:border-primary-500 after:border-b-2',
                'after:transition-all  after:duration-200',
                'hover:after:opacity-100 hover:after:w-full')}
               target={props.target}>{props.children}</a>
        </Link>
    </li>
)

export const PageFooter = () => {
    return (
        <Fragment key="footer">
            <footer
                className="px-12 py-12 lg:py-28 lg:px-4 text-primary-500  md:grid md:grid-cols-3 lg:grid-cols-5 max-w-screen-2xl  lg:mx-auto">
                <div className="w-min h-min pb-9 md:translate-x-0 -translate-x-1/2">
                    <Logo badgeOnly/>
                </div>
                <div className="md:col-start-2 lg:col-start-auto">
                    <h3 className="font-bold text-xl mb-4">Um Okkum</h3>
                    <ul className="space-y-1 mb-12">
                        <FooterItem href="/reyta">Okkara missión</FooterItem>
                        <FooterItem href="/reyta/persondatupolitikkur">Persóndátupolitikkur</FooterItem>
                        <FooterItem href="/reyta/samband">Samband</FooterItem>
                    </ul>
                </div>
                <div>
                    <h3 className="font-bold text-xl mb-4">Reyta</h3>
                    <ul className="space-y-1 mb-12">
                        {/*<FooterItem href="/about/hvi-reyta">Hví Reyta?</FooterItem>*/}
                        <FooterItem href="/reyta/vegleiding">Vegleiðing</FooterItem>
                        <FooterItem href="/reyta/vidskiftatreytir">Viðskiftatreytir</FooterItem>
                    </ul>
                </div>
                <div className="md:col-start-2 lg:col-start-auto">
                    <h3 className="font-bold text-xl mb-4">Fyritøkur</h3>
                    <ul className="space-y-1 mb-12">
                        <FooterItem href="https://biz.reyta.fo">Reyta til fyritøkur</FooterItem>
                        <FooterItem href="/reyta/keypstreytir">Keypstreytir</FooterItem>
                        <FooterItem href="/business/vel-hald">Prísir</FooterItem>
                    </ul>
                </div>
                <div>
                    <h3 className="font-bold text-xl mb-4">Sosialt</h3>
                    <ul className="space-y-1 mb-12">
                        <FooterItem href="https://www.facebook.com/reytafo">Facebook</FooterItem>
                        <FooterItem href="https://instagram.com/reytafo">Instagram</FooterItem>
                    </ul>
                </div>
            </footer>
            {/*<div className="bg-primary-500 flex justify-between text-xs text-slate-400 py-1 px-4" aria-hidden={true}>*/}
            {/*    <div>v. 0.83.0</div>*/}
            {/*    <a href="https://github.com/olavurdj" rel="noreferrer" target="_blank">@olavurdj</a>*/}
            {/*</div>*/}
        </Fragment>
    )
}
