import {ConnectedComponentClass, Hit, HitsProvided} from 'react-instantsearch-core';
import {connectHits, Highlight} from 'react-instantsearch-dom';
import React from 'react';
import {useRouter} from 'next/router';

interface ExtraProps {
    onSelect?: () => void;
}

type Props = ExtraProps & HitsProvided<any>

const Hits = (props: Props) => {
    const {hits} = props
    const {push} = useRouter()
    const onSelect = async (hit: Hit) => {
        await push(`/${hit.slug}`)
        if (props.onSelect) {
            props.onSelect()
        }
    }
    return (
        <div>
        <ol className="px-1 mt-3 mb-0">
            {hits.length === 0 &&
                <div className="px-4 py-2 text-slate-500 text-sm text-italic">Onki úrslit í løtuni. Royn aftur seinni.</div>}
            {hits.map(hit => (
                <li key={hit.objectID} className="px-2" onClick={() => onSelect(hit)}>
                    <a className="rounded-md flex px-2 py-2 items-center hover:bg-primary-100 text-gray-700 cursor-pointer transition-colors block">
                        <Highlight attribute="name" hit={hit} tagName="strong"/>
                        <span className="text-gray-400 text-xs block ml-2">
                            {/*(<Highlight attribute="category.Label" hit={hit} tagName="strong"/>)*/}
                        </span>
                    </a>
                </li>
            ))}
        </ol>
        </div>
    )
};

export const CompanyHits: ConnectedComponentClass<ExtraProps, HitsProvided<any>> = connectHits<Props['onSelect']>(Hits)


//
// const CategoryHitList = ({hits}: { hits: Hit[] }) => (
//     <ol className="divide-y divide-primary-100 ">
//         {hits.length === 0 &&
//             <div className="px-2 py-2 text-slate-500 text-sm text-italic">Ongar vinnugreinar funnar</div>}
//         {hits.map(hit => (
//             <li className="px-2 py-2 hover:bg-primary-50 cursor-pointer transition-colors"
//                 key={hit.objectID}><Highlight attribute="Label" hit={hit} tagName="strong"/></li>
//         ))}
//     </ol>
// );
//
// const CategoryHits = connectHits(CategoryHitList)
