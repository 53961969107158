import classNames from 'classnames';
import Link from 'next/link';

export const LogoType = () => <div
    className="w-12 h-12 grid items-center flex-grow-0 flex-shrink-0 justify-center bg-primary-500 rounded-full text-white text-3xl font-bold">Y</div>

export const Logo = (props: { badgeOnly?: boolean }) => <div className="text-xl flex text-primary-500 items-center">
    <LogoType/><span className={classNames({'md:block': !props.badgeOnly}, 'hidden ml-3 font-bold')}>Reyta</span>
</div>

export const LogoLink = (props: { badgeOnly?: boolean }) => <Link href="/"><a
    className="text-xl flex text-primary-500 items-center flex-shrink-0">
    <LogoType/><span className={classNames({'md:block': !props.badgeOnly}, 'hidden ml-3 font-bold')}>Reyta</span>
</a></Link>
