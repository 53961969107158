import {ForwardedRef, forwardRef, ReactNode} from 'react';
import classNames from 'classnames';
import UseAnimations from 'react-useanimations';
import loading from 'react-useanimations/lib/loading';

interface ButtonProps {
    disabled?: boolean;
    loading?: boolean;
    children: ReactNode;
    secondary?: boolean;
    onClick?: () => void;
    className?: string;
    type?: 'button' | 'submit' | 'reset';
}

export const Button = forwardRef((props: ButtonProps, ref: ForwardedRef<HTMLButtonElement>) => {
    const className = classNames('disabled:cursor-not-allowed border relative transition-colors duration-400 border-primary-500 leading-4 px-[18px] py-[15px] rounded-md font-display font-bold text-sm tracking-wide', {
        'bg-primary-500 text-white hover:bg-primary-300 hover:border-primary-300': !props.secondary,
        'bg-primary-50 text-primary-500 hover:bg-primary-100': props.secondary,
        '!bg-gray-300 !border-gray-300': props.disabled && !props.loading
    }, props.className)
    return (
        <button onClick={props.onClick}
                ref={ref}
                disabled={props.disabled || props.loading}
                type={props.type}
                className={className}>
            <div className={classNames({'invisible': props.loading})}>
                {props.children}
            </div>
            {props.loading &&
                <div className="absolute inset-0 flex items-center justify-center"><UseAnimations strokeColor="#ffffff"
                                                                                                  animation={loading}
                                                                                                  size={24}/></div>}
        </button>
    )

})
Button.displayName = 'Button'
