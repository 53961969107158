import {SideMenu} from '@components/sidemenu';
import {LogoLink} from '@components/logo';
import {Button} from '@components/button';
import {ChevronDownIcon, LogoutIcon, MenuAlt4Icon, ViewListIcon, XIcon} from '@heroicons/react/solid';
import {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import classNames from 'classnames';
import {signIn, signOut, useSession} from 'next-auth/react';
import {Menu, Transition} from '@headlessui/react';
import {StarIcon, SearchIcon, UserIcon} from '@heroicons/react/outline';
import splitbee from '@splitbee/web';

interface PageHeaderProps {
    border?: boolean;
    fluid?: boolean;
}

import initials from 'initials'
import Link from 'next/link';
import {AppSearch} from '@components/app-search';
import {UserMenu} from '@components/user-menu';

const HeaderActions = (props: { toggleMenu: () => void }) => {
    const session = useSession()
    const isAuthenticated = session.status === 'authenticated'
    const [showSearch, setShowSearch] = useState(false)
    const inputRef = useRef<HTMLInputElement>(null)
    return (
        <>
            {isAuthenticated ? (
                <Fragment key="user-authenticated">
                    <div className="hidden md:block">
                        <UserMenu/>
                    </div>
                </Fragment>
            ) : (
                <Fragment key="user-unauthenticated">
                    <Button className="hidden md:block flex-shrink-0" onClick={signIn}>Rita
                        Inn</Button>
                    <Link href="https://biz.reyta.fo"><Button className="hidden md:block flex-shrink-0" secondary>Fyritøka</Button></Link>
                </Fragment>
            )}
            <div className="md:hidden">
                <button
                    className="h-12 w-12 flex items-center justify-center hover:bg-opacity-10
                text-primary-500
                            transition-colors rounded-md hover:bg-slate-500"
                    onClick={() => setShowSearch(true)}>
                    <SearchIcon className=" w-8 h-8 pointer-events-none" aria-hidden={true}/>
                </button>
                <Transition show={showSearch}
                            enter="transition-opacity duration-200"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity duration-400"
                            leaveFrom="opacity-100"
                            as="div"
                            className="absolute bg-white left-0 w-full z-20 bottom-0 flex py-3 px-4"
                            leaveTo="opacity-0">
                    <div className="flex-grow">
                        <AppSearch mobile onSelect={() => setShowSearch(false)}/>
                    </div>
                    <button
                        className="h-12 w-12 ml-4 flex items-center justify-center hover:bg-opacity-10
                text-primary-500
                            transition-colors rounded-md hover:bg-slate-500"
                        onClick={() => setShowSearch(false)}>
                        <XIcon className=" w-8 h-8 pointer-events-none" aria-hidden={true}/>
                    </button>
                </Transition>
            </div>
            <button
                className="h-12 w-12 flex items-center justify-center hover:bg-opacity-10
                text-primary-500
                            transition-colors rounded-md hover:bg-slate-500"
                onClick={props.toggleMenu}>
                <MenuAlt4Icon className=" w-8 h-8 pointer-events-none" aria-hidden={true}/>
            </button>
        </>
    )
}
export const FluidHeader = (props: PageHeaderProps) => {
    const [open, setOpen] = useState(false)
    const toggleMenu = useCallback(() => {
        if (open) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, [open, setOpen])

    return (
        <Fragment key="fluid-header">
            <SideMenu open={open} onClose={() => setOpen(false)}/>
            <nav
                className={classNames('bg-white pt-12 sm:pt-3 py-3 text-primary relative px-4 flex', {'border-b border-gray-200': props.border})}>
                <div style={{flexBasis: 'calc((100vw - 1280px - 32px) / 2)'}} className="flex-shrink-0">
                    <LogoLink badgeOnly/>
                </div>
                <div className="flex-grow md:hidden"/>
                <div
                    className="mx-4 ml-4 max-w-[1248px] w-full mx-4 hidden md:block"
                    style={{flexBasis: 'calc((1248px / 3 * 2) + 32px)'}}>
                    <div>
                        <AppSearch/>
                    </div>
                </div>
                <div className="flex-grow-1 justify-end flex flex-1 space-x-3 flex-shrink-0 ml-3">
                    <HeaderActions toggleMenu={toggleMenu}/>
                </div>
                {/*<div className={classNames({'xl:container mx-auto ': !props.fluid}, 'px-4 justify-between flex')}>*/}
                {/**/}
                {/*</div>*/}
            </nav>
        </Fragment>
    )
}

export const PageHeader = (props: PageHeaderProps) => {
    const session = useSession()
    const isAuthenticated = session.status === 'authenticated'

    const [open, setOpen] = useState(false)
    const toggleMenu = useCallback(() => {
        if (open) {
            setOpen(false)
        } else {
            setOpen(true)
        }
    }, [open, setOpen])
    const rootClasses = classNames('bg-white pt-12 sm:pt-3 py-3 text-primary relative', {'border-b border-gray-200': props.border})
    return (
        <>
            <SideMenu open={open} onClose={() => setOpen(false)}/>
            <nav className={rootClasses}>
                <div className={classNames({'max-w-screen-2xl mx-auto ': !props.fluid}, 'px-4 justify-between flex')}>
                    <LogoLink badgeOnly={props.fluid}/>
                    <div className=" flex space-x-3 items-center right-4 top-0 bottom-0">
                        {isAuthenticated ? (
                            <UserMenu className="hidden md:block"/>
                        ) : (
                            <>
                                <Button className="flex-shrink-0 hidden md:block" onClick={signIn}>Rita
                                    Inn</Button>
                                <Link href="https://biz.reyta.fo"><Button
                                    className="flex-shrink-0 hidden md:block" secondary>Fyritøka</Button></Link>
                            </>
                        )}
                        <button
                            className="outline-none h-12 w-12 flex items-center justify-center hover:bg-opacity-10
                            transition-colors rounded-md hover:bg-slate-500"
                            onClick={toggleMenu}>
                            <MenuAlt4Icon className=" w-8 h-8 pointer-events-none" aria-hidden={true}/>
                        </button>
                    </div>
                </div>
            </nav>
        </>
    )
}
