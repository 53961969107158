import React, {useState} from 'react';
import {AppSearchResults} from './results';
import {AppSearchInput} from '@components/app-search/input';
import {useAlgoliaClient} from '@components/app-search/provider';
import classNames from 'classnames';
import {useFocus} from '@react-aria/interactions';
import { InstantSearch } from 'react-instantsearch-dom';

interface Props {
    standalone?: boolean;
    mobile?: boolean;
    onSelect?: () => void;
}

export const AppSearch = (props: Props) => {
    const searchClient = useAlgoliaClient()
    const [isResultsVisible, setIsResultsVisible] = useState(false)
    const {focusProps} = useFocus({
        onFocus: () => {
            setIsResultsVisible(true)
        },
        onBlur: () => {
            setIsResultsVisible(false)
        }
    })
    return (
        <InstantSearch indexName="production_businesses" searchClient={searchClient}>
            <div className="relative">
                <AppSearchInput placeholder="Finn fyritøku her"
                                focusOnMount={props.mobile}
                                {...focusProps}
                                className={classNames('font-medium', {
                                    'bg-[#F2F4F8]': !props.standalone,
                                    '!py-4': props.standalone,
                                    '!bg-white placeholder:not-italic': props.mobile
                                })}/>
                <AppSearchResults showOnEmpty={false} visible={isResultsVisible}
                                  onSelect={props.onSelect}/>
            </div>
        </InstantSearch>
    )
}
