import {connectSearchBox} from 'react-instantsearch-dom';
import React, {useRef} from 'react';
import classNames from 'classnames';
import {SearchBoxProvided} from 'react-instantsearch-core';
import {FocusResult} from '@react-aria/interactions';
import {useRouter} from 'next/router';

type InputProps =
    SearchBoxProvided
    & FocusResult['focusProps']
    & { className?: string; placeholder: string, focusOnMount?: boolean }

const Input = (props: InputProps) => {
    const {
        placeholder,
        currentRefinement,
        isSearchStalled,
        className,
        refine,
        createURL,
        indexContextValue,
        ...rest
    } = props as (typeof props & { createURL: any, indexContextValue: any })
    const ref = useRef<HTMLInputElement>(null)
    const {push} = useRouter()
    const handleSubmit = (event: any) => {
        event.preventDefault();
        if (!currentRefinement) {
            return
        }
        push({pathname: '/leita', search: '?q=' + encodeURIComponent(currentRefinement)})
        if (typeof ref.current?.blur === 'function') {
            ref.current?.blur();
        }
    }
    return (
        <form onSubmit={handleSubmit}>
            <input
                ref={ref}
                className={classNames('placeholder:text-primary-500 placeholder:text-opacity-70 focus:ring-0 px-4 py-3 border-0 bg-white text-primary-500 w-full rounded-md', className)}
                type="search"
                autoFocus={props.focusOnMount}
                placeholder={placeholder}
                value={currentRefinement}
                onChange={event => refine(event.currentTarget.value)}
                {...rest}
            />
        </form>
    )
}

export const AppSearchInput = connectSearchBox<InputProps>(Input)
