import {Fragment, ReactNode} from 'react';
import {Dialog, Transition} from '@headlessui/react';
import {XIcon} from '@heroicons/react/solid';
import Link from 'next/link';
import {useSession} from 'next-auth/react';
import {UserMenu} from '@components/user-menu';
import classNames from 'classnames';

interface SideMenuProps {
    open: boolean;
    onClose: () => void;
}

interface LinkItemProps {
    className?: string;
    children: ReactNode;
    href: string;
}

const LinkItem = (props: LinkItemProps) => (<li className={classNames('py-3', props.className)}>
    <Link href={props.href}>
        <a> {props.children}</a>
    </Link>
</li>)

export const SideMenu = (props: SideMenuProps) => {
    const {status} = useSession()
    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="fixed z-50 inset-0 overflow-hidden" onClose={props.onClose}>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay
                            className="absolute inset-0 bg-slate-500 bg-opacity-75 transition-opacity"
                            style={{willChange: 'opacity'}}/>
                    </Transition.Child>
                    <div className="fixed inset-y-0 right-0 md:max-w-md flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-out-expo duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-   0"
                            leave="transform transition ease-out-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="relative w-screen max-w-md will-change-transform">
                                <div
                                    className="h-full w-full flex flex-col bg-accent-50 shadow-xl overflow-y-scroll">
                                    <Dialog.Title
                                        className="sr-only">Síðuyvirlit</Dialog.Title>
                                    <div className="relative flex-1 flex-col items-center md:items-start grow flex">
                                        {/* Replace with your co    ntent */}
                                        <div
                                            className="py-3 flex justify-end items-center space-x-1 w-full sm:pt-4 pt-12 px-4">
                                            {status === 'authenticated' && <UserMenu className="block md:hidden"/>}
                                            <button
                                                className="focus:outline-none h-14 w-14 flex items-center justify-center hover:bg-opacity-10 transition-colors rounded-md hover:bg-slate-500 text-primary-500"
                                                onClick={() => props.onClose()}>
                                                <XIcon className="w-8 h-8
                     pointer-events-none" aria-hidden={true}/>
                                            </button>
                                        </div>
                                        <nav
                                            className="pt-6 text-primary-500 text-2xl font-bold px-[70px] w-full pb-[116px] grow sm:pb-0 items-center flex sm:items-start">
                                            <ul>
                                                <LinkItem href="/">Heim</LinkItem>
                                                {status !== 'authenticated' &&
                                                    <LinkItem className="md:hidden" href="/login">Rita Inn</LinkItem>}
                                                <LinkItem href="/vinnugreinar">Vinnugreinar</LinkItem>
                                                <LinkItem href="/reyta/vegleiding">Vegleiðing</LinkItem>
                                                {/*<LinkItem href="/about">FAQ</LinkItem>*/}
                                                {/*<LinkItem href="#" className="md:hidden">Fyritøkur</LinkItem>*/}
                                            </ul>
                                        </nav>
                                        {/* /End replace */}
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
